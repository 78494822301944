import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <Container fluid>
            <Row>
              <Col>
                <h4 className="text-center fSb fs-24">
                  Submitting the result...
                </h4>
              </Col>
            </Row>

            <Row>
              <Col className="text-center mt-5">
                <Spinner animation="border" variant="primary" size="lg" />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Loader;
