import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./customcss/custom.css";
import "./customfont/stylesheet.css";
import ReactGA from "react-ga";

import { Provider } from "react-redux";
import store from "./store";
import { MIXPANEL_ID, REACT_APP_KEY } from "./constants";
import { sprig } from "@sprig-technologies/sprig-browser";
import mixpanel from "mixpanel-browser";

export const Sprig = sprig.configure({
  environmentId: "ab5RyiXqJY",
});

ReactGA.initialize(REACT_APP_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

mixpanel.init(MIXPANEL_ID, {
  debug: false,
  track_pageview: false,
  persistence: "localStorage",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
