import InsertsNav from "../widgets/InsertsNav";
import Text from "../ui/Text";
import axios from "axios";
import {
  REACT_APP_STRAPI_URL,
  REACT_APP_STRAPI_KEY,
  AB_VERSION,
} from "../../constants";
import useSWR from "swr";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import badgeSurveyInserts from "../../img/badge-survey-inserts.svg";
import { Box, Grid, Button } from "@mui/material";
import moment from "moment/moment";
import { goToQuizPage } from "../../utils/quizPusher";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

const quizPageFetcher = (url) =>
  axios
    .get(url, { headers: { Authorization: `Bearer ${REACT_APP_STRAPI_KEY}` } })
    .then((res) => res.data.data);

function useQuizPage(qpid) {
  const { data, error, isLoading } = useSWR(
    `${REACT_APP_STRAPI_URL}/quiz-pages/${qpid}`,
    quizPageFetcher
  );

  return {
    data,
    error,
    isLoading,
  };
}

const ProgressGraph = () => {
  const { emotionCategory } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const progress = Number(searchParams.get("p"));
  const qpid = searchParams.get("qpid");
  const { data, isLoading } = useQuizPage(qpid, emotionCategory);

  const header = data?.header;
  const reviewText = data?.content?.review?.review;
  const reviewDate = data?.content?.review?.review_date;
  const reviewerName = data?.content?.review?.reviewer;
  const reviewPp = data?.content?.review?.reviewer_img.url;

  const prevQuizId = data?.previous_page?.id;
  const prevQuizType = data?.previous_page?.content.__component;

  const nextQuizId = data?.next_page?.id;
  const nextQuizType = data?.next_page.content.__component;

  const goToPrevQuestion = () => {
    goToQuizPage(navigate, emotionCategory, prevQuizType, prevQuizId, progress);
  };

  const goToNextQuestion = () => {
    goToQuizPage(navigate, emotionCategory, nextQuizType, nextQuizId, progress);
  };

  useEffect(() => {
    mixpanel.track(`$pageview:${emotionCategory}-progress-path`, {
      page: `${emotionCategory}-progress-path`,
      abVersion: AB_VERSION,
    });
  }, [emotionCategory]);

  return (
    <div className="flex items-center justify-center mt-3 p-3">
      <div className="w-[468px]">
        <InsertsNav goToPrevQuestion={goToPrevQuestion} />

        <div className="text-center mt-12">
          {isLoading && (
            <Text fontSize="20px" fontWeight={500} color="#212121">
              Loading your content...
            </Text>
          )}

          {!isLoading && (
            <>
              <Text fontSize="20px" fontWeight={500} color="#212121">
                {header}
              </Text>

              <Box
                sx={{
                  width: { xs: "80%", sm: "260px" },
                  marginX: "auto",
                  marginTop: "48px",
                  marginBottom: "48px",
                }}
              >
                <img
                  src={badgeSurveyInserts}
                  width="100%"
                  alt="badge-survey-inserts"
                />
              </Box>

              <Grid container spacing={2} sx={{ marginBottom: "45px" }}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url("${reviewPp}")`,
                        height: { xs: "100px", sm: "120px" },
                        width: { xs: "100px", sm: "120px" },
                        backgroundSize: "cover",
                        borderRadius: "100px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Text
                        className="text-left"
                        sx={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "28px",
                        }}
                      >
                        {reviewText}
                      </Text>
                      <Text
                        className="text-left"
                        sx={{
                          fontWeight: 400,
                          fontSize: { xs: "12px", sm: "14px" },
                          lineHeight: "30px",
                          color: "rgba(33, 33, 33, 0.6)",
                        }}
                      >
                        {`${reviewerName}, ${moment(reviewDate).format(
                          "MMM YYYY"
                        )}`}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  background: "white",
                  paddingTop: "15px",
                  paddingBottom: "10px",
                  paddingX: 0,
                }}
              >
                <Button
                  className="btn-cta"
                  variant="contained"
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    height: "64px",
                    fontSize: { xs: "14px", sm: "18px" },
                    paddingY: { xs: "12px", sm: "20px" },
                    marginBottom: "5px",
                  }}
                  fullWidth
                  onClick={goToNextQuestion}
                >
                  <Text fontSize="18px" fontWeight={500} color="#ffffff">
                    Continue
                  </Text>
                </Button>

                <Text
                  className="text-left"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "12px", sm: "14px" },
                    lineHeight: "30px",
                    color: "#666666",
                  }}
                >
                  Real Apple AppStore review from Ahead user.
                </Text>
              </Box>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressGraph;
