import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { REACT_APP_API_URL } from "../constants";
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import FinalSlider from "./widgets/FinalSlider";
import iconfile from "../img//icon-file.svg";
import Footer from "./widgets/Footer";

const FinalResult = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState("");
  const [data, setData] = useState();
  const [username, setUsername] = useState();
  const [person, setPerson] = useState(0);
  const currentURL = window.location.origin + "/feedback-start/" + id;
  const textAreaRef = useRef(null);

  // To get user detail using useEffect
  useEffect(() => {
    const getData = () => {
      let ApiURL = REACT_APP_API_URL + "results/" + id;
      axios
        .get(ApiURL)
        .then((res) => {
          const resp = res.data;
          if (res.status === 200) {
            const feedbackInput = {
              feedbacks: resp.data,
              answer: resp.data.map((r) => ({ answer: r.myAnswer })),
            };
            setUsername(res.data.username);
            const feedbackOutput = feedback(feedbackInput);
            setData(feedbackOutput);
          } else {
            console.warn("Error:", resp.message);
          }
        })
        .catch((err) => {
          console.warn("Error:", err);
        });
    };

    // To get the initiator user details api.
    getData();
  }, [id, navigate]);

  const feedback = (fedata) => {
    const question = fedata.answer.map((iniAns) => {
      iniAns.feedCount = {
        a0: 0,
        a1: 0,
        a2: 0,
        a3: 0,
        a4: 0,
        a5: 0,
        a6: 0,
      };
      return iniAns;
    });
    setPerson(fedata.feedbacks[0].answers.length);
    for (const [fkey, fval] of fedata.feedbacks.entries()) {
      for (const a of fval.answers) {
        question[fkey].question = fval.question;
        if (a === "0") {
          question[fkey].feedCount.a0++;
        } else if (a === "1") {
          question[fkey].feedCount.a1++;
        } else if (a === "2") {
          question[fkey].feedCount.a2++;
        } else if (a === "3") {
          question[fkey].feedCount.a3++;
        } else if (a === "4") {
          question[fkey].feedCount.a4++;
        } else if (a === "5") {
          question[fkey].feedCount.a5++;
        } else if (a === "6") {
          question[fkey].feedCount.a6++;
        }
      }
    }

    console.debug("question", question);

    return question;
  };

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <Container fluid>
            <Row>
              <Col>
                <p className="text-center mb-5">
                  <img src={iconfile} alt="iconfile" />
                </p>
                <h4 className="text-center fs-28 fSb">
                  {person} of your friends have completed the test.
                </h4>
              </Col>
            </Row>

            <Row>
              <Col className="mt-5">
                <Form>
                  <Form.Group>
                    <Form.Control
                      size="lg"
                      type="text"
                      ref={textAreaRef}
                      readOnly
                      value={currentURL}
                      className="text-center fs-16"
                    />
                    <br />
                  </Form.Group>

                  {document.queryCommandSupported("copy") && (
                    <div className="text-center">
                      <Button
                        variant="primary"
                        size="lg"
                        onClick={copyToClipboard}
                        block
                      >
                        Copy Link
                      </Button>
                      <p className="text-center">{copySuccess}</p>
                    </div>
                  )}

                  <Form.Group className="mt-5 text-center">
                    <TwitterShareButton
                      windowWidth={window.screen.availWidth}
                      windowHeight={window.screen.availHeight}
                      className="border-0 bg-transparent"
                      url={currentURL}
                      title="Help me become more emotional intelligent by answering 10 questions about me. You could help me a lot by providing honest feedback. Fill-out my honest feedback questionnaire here:"
                      style={{ padding: "0px 5px" }}
                    >
                      <TwitterIcon
                        logofillcolor="white"
                        size={40}
                        round={true}
                      />
                    </TwitterShareButton>

                    <WhatsappShareButton
                      windowWidth={window.screen.availWidth}
                      windowHeight={window.screen.availHeight}
                      className="border-0 bg-transparent"
                      url={currentURL}
                      title="Help me become more emotional intelligent by answering 10 questions about me. You could help me a lot by providing honest feedback. Fill-out my honest feedback questionnaire here: "
                      style={{ padding: "0px 5px" }}
                    >
                      <WhatsappIcon
                        logofillcolor="white"
                        size={40}
                        round={true}
                      />
                    </WhatsappShareButton>

                    <FacebookShareButton
                      windowWidth={window.screen.availWidth}
                      windowHeight={window.screen.availHeight}
                      className="border-0 bg-transparent"
                      url={currentURL}
                      quote="Help me become more emotional intelligent by answering 10 questions about me. You could help me a lot by providing honest feedback. Fill-out my honest feedback questionnaire here:"
                      style={{ padding: "0px 5px" }}
                    >
                      <FacebookIcon
                        logofillcolor="white"
                        size={40}
                        round={true}
                      />
                    </FacebookShareButton>

                    <EmailShareButton
                      windowWidth={window.screen.availWidth}
                      windowHeight={window.screen.availHeight}
                      className="border-0 bg-transparent"
                      url={currentURL}
                      subject="Sharing a link to provide a feedback"
                      body="Help me become more emotional intelligent by answering 10 questions about me. You could help me a lot by providing honest feedback. Fill-out my honest feedback questionnaire here:"
                      separator=" "
                      style={{ padding: "0px 5px" }}
                    >
                      <EmailIcon logofillcolor="white" size={40} round={true} />
                    </EmailShareButton>
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col>
                <p className="border-top border-2"></p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <FinalSlider questionData={data} username={username}></FinalSlider>
      <Footer />
    </Container>
  );
};

export default FinalResult;
