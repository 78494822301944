import { Box } from "@mui/material";
import logo from "../../img/Logo.svg";

const NavbarLogo = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <img
        src={logo}
        alt="ahead-logo"
        style={{ height: "24px", cursor: "pointer" }}
      />
    </Box>
  );
};

export default NavbarLogo;
