import NavbarLogo from "./ui/NavbarLogo";
import Text from "./ui/Text";
import { Box, Button } from "@mui/material";

import ghostBell from "../img/ghost-bell.svg";

const Waitlist = () => {
  return (
    <div className="flex items-center justify-center flex-col mt-3">
      <NavbarLogo />

      <div className="px-16 md:px-36 mt-16 md:mt-32">
        <img src={ghostBell} alt="" srcset="" className="m-auto" />

        <div className="text-center mt-4">
          <Text fontSize="32px" fontWeight={500} color="#212121">
            Don't worry - we'll be reaching to you soon.
          </Text>

          <Text fontSize="24px" color="#A2A2A2" className="mt-2">
            Be the first to try Ahead App on Android.
          </Text>

          <Text fontSize="24px" color="#A2A2A2">
            Follow Ahead App on Instagram (@ahead_app) to get the latest update
            from us.
          </Text>

          <Button
            className="btn-cta"
            sx={{
              paddingX: "60px",
              fontFamily: "euclid_circular_bmedium !important",
              marginTop: "60px",
              height: "72px",
              width: {
                md: "468px",
              },
            }}
            disableRipple
            onClick={() => {
              window.open("https://www.instagram.com/ahead_app", "_blank");
            }}
          >
            <Box>
              <Text fontSize="16px" fontWeight={500} color="#ffffff">
                Go to Ahead's Instagram
              </Text>
            </Box>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
