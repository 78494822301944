import { Box } from "@mui/material";
import Text from "../ui/Text";
import axios from "axios";
import QuizProgress from "../widgets/QuizProgress";
import {
  REACT_APP_STRAPI_KEY,
  REACT_APP_STRAPI_URL,
  journeysMap,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { goToQuizPage } from "../../utils/quizPusher";
import { Button } from "@mui/material";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { AB_VERSION } from "../../constants";

const quizInfoFetcher = (url) =>
  axios
    .get(url, { headers: { Authorization: `Bearer ${REACT_APP_STRAPI_KEY}` } })
    .then((res) => res.data.data);

function useQuizInfo(journeyId) {
  const { data, error, isLoading } = useSWR(
    `${REACT_APP_STRAPI_URL}/quizzes/${journeyId}`,
    quizInfoFetcher
  );

  return {
    data,
    error,
    isLoading,
  };
}

const QuestionCard = ({ title, onClick }) => {
  return (
    <Button
      className="btn-quiz"
      sx={{
        display: "flex",
        justifyContent: "start",
        paddingX: "24px",
        paddingY: "16px",
        width: "100%",
        height: "76px",
        textAlign: "left",
        borderRadius: "8px !important",
        border: "1px solid rgba(0, 0, 0, 0.06) !important",
        // border: "1px solid rgba(0, 0, 0, 0.06)",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
        marginBottom: "12px",
        fontFamily: "euclid_circular_bmedium !important",
      }}
      onClick={onClick}
      fullWidth
      disableRipple
    >
      <Box>
        <Text
          fontSize="16px"
          fontWeight={500}
          lineHeight="30px"
          color="#212121"
        >
          {title}
        </Text>
      </Box>
    </Button>
  );
};

const DeviceCheck = () => {
  const { emotionCategory } = useParams();
  const navigate = useNavigate();

  const { data } = useQuizInfo(journeysMap[emotionCategory]);

  useEffect(() => {
    mixpanel.track(`$pageview-sat:quiz-start`, {
      page: `journeys`,
      abVersion: AB_VERSION,
    });
  }, []);

  return (
    <div className="flex items-center justify-center mt-3 p-3">
      <div className="w-[468px]">
        <QuizProgress
          currentQuestion={1}
          totalQuestions={data?.question_count + 1}
        />
        <div className="text-center mt-12">
          <Text fontSize="20px" fontWeight={500} color="#212121">
            Do you have an iPhone? Our app is currently only available on
            iPhones (Android coming soon).
          </Text>
          <Box
            sx={{
              width: {
                md: "468px",
                sm: "calc(100% - 80px)",
                xs: "100%",
              },
              marginX: "auto",
              marginTop: "3rem",
              paddingX: "0",
            }}
          >
            <QuestionCard
              title={"Yes"}
              onClick={() => {
                goToQuizPage(
                  navigate,
                  emotionCategory,
                  data.first_quiz_page?.content.__component,
                  data.first_quiz_page?.id,
                  2
                );
              }}
            />

            <QuestionCard
              title={"No"}
              onClick={() => {
                navigate("/waitlist");
              }}
            />
          </Box>
        </div>
      </div>
      <Text
        sx={{
          fontSize: { xs: "14px", sm: "16px" },
          textAlign: "center",
          width: "100%",
          marginX: "auto",
          marginBottom: "10px",
          fontWeight: 500,
          color: "rgba(0, 0, 0, 0.6)",
          position: "fixed",
          bottom: "0",
        }}
      >
        Powered by 🖤 ahead app
      </Text>
    </div>
  );
};

export default DeviceCheck;
