export const quizPathMaps = {
  "quiz-page.question-page": "/question",
  "quiz-page.progress-page": "/progress-graph",
  "quiz-page.review-page": "/review",
  "quiz-page.solution-page": "/solution",
};

export const goToQuizPage = (
  navigate,
  emotionCategory,
  typePage,
  qpid,
  progress
) => {
  const subPath = quizPathMaps[typePage];

  navigate(`/${emotionCategory}${subPath}?qpid=${qpid}&p=${progress}`);
};
