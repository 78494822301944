import QuizProgress from "../widgets/QuizProgress";
import Text from "../ui/Text";
import { REACT_APP_STRAPI_KEY, REACT_APP_STRAPI_URL } from "../../constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";

import { Box, Button } from "@mui/material";
import QuestionCard from "../ui/QuestionCard";
import { goToQuizPage } from "../../utils/quizPusher";
import mixpanel from "mixpanel-browser";
import { AB_VERSION } from "../../constants";

const quizPageFetcher = (url) =>
  axios
    .get(url, { headers: { Authorization: `Bearer ${REACT_APP_STRAPI_KEY}` } })
    .then((res) => res.data.data);

function useQuizPage(qpid) {
  const { data, error, isLoading } = useSWR(
    `${REACT_APP_STRAPI_URL}/quiz-pages/${qpid}`,
    quizPageFetcher
  );

  return {
    data,
    error,
    isLoading,
  };
}

const Question = () => {
  const { emotionCategory } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const progress = Number(searchParams.get("p"));
  const qpid = searchParams.get("qpid");

  const tempId = localStorage.getItem("temporaryId");

  const { data, isLoading } = useQuizPage(qpid, emotionCategory);

  const visualImg = data?.content?.visual?.url;
  const currQuestion = data
    ? {
        questionId: String(qpid),
        content: data.content?.question?.text,
        type: data.content?.question?.type,
        options:
          data.content?.question?.question_choices?.map((e) => ({
            optionId: e.id,
            content: e.choice.text,
          })) ?? [],
        prevQuizId: data.previous_page?.id,
        prevQuizType: data.previous_page?.content?.__component,
        nextQuizId: data.next_page?.id,
        nextQuizType: data.next_page?.content?.__component,
      }
    : null;

  const goToPrevQuestion = () => {
    goToQuizPage(
      navigate,
      emotionCategory,
      currQuestion.prevQuizType,
      currQuestion.prevQuizId,
      progress - 1
    );
  };

  return (
    <div className="flex items-center justify-center mt-3 p-3">
      <div className="w-[468px]">
        <QuizProgress
          currentQuestion={progress}
          totalQuestions={12}
          goToPrevQuestion={goToPrevQuestion}
        />

        <div className="text-center mt-12">
          {isLoading && (
            <Text fontSize="20px" fontWeight={500} color="#212121">
              Loading your content...
            </Text>
          )}

          <Text fontSize="20px" fontWeight={500} color="#212121">
            {currQuestion?.content}
          </Text>

          {currQuestion?.type === "MULTISELECT" && (
            <Text
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "#212121",
                opacity: "0.6",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              Select all that apply
            </Text>
          )}

          {visualImg && (
            <img
              src={visualImg}
              alt="app-visual"
              width="100%"
              height={"200px"}
              style={{
                objectFit: "cover",
                marginBottom: "40px",
                marginTop: "24px",
              }}
            />
          )}

          <Box
            sx={{
              width: {
                md: "468px",
                sm: "calc(100% - 80px)",
                xs: "100%",
              },
              marginX: "auto",
              marginTop: "2rem",
              paddingX: "0",
            }}
          >
            {currQuestion?.options.map((e, i) => (
              <QuestionCard
                key={i}
                title={e.content}
                type={currQuestion?.type}
                submitAnswer={() => {
                  if (
                    currQuestion?.nextQuizType === "quiz-page.calculate-page"
                  ) {
                    mixpanel.track(
                      `$pageview-sat:${emotionCategory}-transition`,
                      {
                        page: `${emotionCategory}/question`,
                        abVersion: AB_VERSION,
                      }
                    );

                    // window.open(
                    //   `http://localhost:3000/${emotionCategory}/loading?qpid=${
                    //     Number(qpid) + 1
                    //   }&userId=${tempId}`,
                    //   "_self"
                    // );

                    // window.open(
                    //   `https://staging.ahead-app.com/${emotionCategory}/loading?qpid=${
                    //     Number(qpid) + 1
                    //   }&userId=${tempId}`,
                    //   "_self"
                    // );

                    window.open(
                      `https://ahead-app.com/${emotionCategory}/loading?qpid=${
                        Number(qpid) + 1
                      }&userId=${tempId}`,
                      "_self"
                    );

                    return;
                  }

                  goToQuizPage(
                    navigate,
                    emotionCategory,
                    currQuestion.nextQuizType,
                    currQuestion.nextQuizId,
                    progress + 1
                  );
                }}
              />
            ))}
          </Box>

          {currQuestion?.type === "MULTISELECT" && (
            <Button
              className="btn-cta"
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 500,
                height: "64px",
                fontSize: { xs: "14px", sm: "18px" },
                paddingY: { xs: "12px", sm: "20px" },
                marginBottom: "5px",
              }}
              fullWidth
              onClick={() => {
                goToQuizPage(
                  navigate,
                  emotionCategory,
                  currQuestion.nextQuizType,
                  currQuestion.nextQuizId,
                  progress + 1
                );
              }}
            >
              <Text fontSize="18px" fontWeight={500} color="#ffffff">
                Continue
              </Text>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Question;
