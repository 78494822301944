import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import backIcon from "../../img/back-icon.svg";

const QuizProgress = ({
  goToPrevQuestion,
  currentQuestion,
  totalQuestions,
}) => {
  const progress = (currentQuestion * 100) / totalQuestions;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <img
          src={backIcon}
          alt="back-icon"
          height={"22px"}
          onClick={goToPrevQuestion}
          style={{
            cursor: "pointer",
          }}
        />
        <Box
          sx={{
            textAlign: "center",
            flexGrow: "1",
            color: "#A2A2A2",
            fontWeight: 500,
            paddingRight: "40px",
          }}
        >
          Question {currentQuestion} of {totalQuestions}
        </Box>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "12px",
          borderRadius: "8px",
          marginX: "5px",
          backgroundColor: "#F6F7F8",
          ".MuiLinearProgress-bar": {
            backgroundColor: "#6442EF",
            borderRadius: "8px",
          },
        }}
      />
    </>
  );
};

export default QuizProgress;
