export const REACT_APP_URL = "https://selfawareness.ahead-app.com";

export const REACT_APP_API_URL =
  "https://ahead-production.uc.r.appspot.com/sat-web/";
// export const REACT_APP_API_URL = "http://localhost:3000/sat-web/";

export const REACT_APP_KEY = "UA-180545050-1";

export const REACT_APP_STRAPI_URL =
  "https://web-api-staging-zo7m4253jq-uc.a.run.app/api";

export const REACT_APP_STRAPI_KEY =
  "e6f9b476b5beecd6aa205a91feb13c7bc247fd079143fac3129883f20a16082c004f0f8b6229df8f03d675e2d461e820c368998be5ac3b50b1e818755d32389bac98b0288b510b9996804f03896b5aaca94be0d5ae34a136d1b055b38609240dfb43f117973bd3adee2e8c9880192416625ed45dc4b8c75da107828c03374046";

// production
export const MIXPANEL_ID = "3a28e17404bb3c900f409966161bdb51";

// personal
// export const MIXPANEL_ID = "f720eefd94c8aefc155cb01b25238f3c";

// staging
// export const MIXPANEL_ID = "c9c1689c6f6d064409171e47f0f55063";

export const journeysMap = {
  anger: 1,
  procrastination: 2,
  confidence: 3,
};

export const AB_VERSION = "A";
