import { Box, Button } from "@mui/material";
import Text from "./Text";
import { useState } from "react";

const QuestionCard = ({ title, type, submitAnswer, onClick }) => {
  const [isSelected, setIsSelected] = useState(false);

  const toggle = () => {
    console.log("TYPE", type);
    if (type === "MULTISELECT") {
      setIsSelected(!isSelected);
      return;
    }

    console.log("SUBMITTING");
    submitAnswer();
  };
  return (
    <Button
      className="btn-quiz"
      sx={{
        display: "flex",
        justifyContent: "start",
        paddingX: "24px",
        paddingY: "16px",
        width: "100%",
        height: "76px",
        textAlign: "left",
        borderRadius: "8px !important",
        border: isSelected
          ? "2px solid #6442EF !important"
          : "1px solid rgba(0, 0, 0, 0.06) !important",
        // border: "1px solid rgba(0, 0, 0, 0.06)",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
        marginBottom: "12px",
        fontFamily: "euclid_circular_bmedium !important",
      }}
      onClick={toggle}
      fullWidth
      disableRipple
    >
      <Box>
        <Text
          fontSize="16px"
          fontWeight={500}
          lineHeight="30px"
          color="#212121"
        >
          {title}
        </Text>
      </Box>
    </Button>
  );
};

export default QuestionCard;
