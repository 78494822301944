import { Box, Skeleton } from "@mui/material";

const JourneyCardSkeleton = () => {
  return (
    <Box display="flex">
      <Skeleton
        sx={{
          width: { xs: "80px", sm: "120px" },
          height: { xs: "95px", sm: "100px" },
          borderRadius: "8px",
          transform: "scale(1, 1)",
          marginBottom: "12px",
        }}
      />
      <Box flexGrow={1} paddingLeft="10px">
        <Skeleton height={"45px"} width={"60%"} />
        <Skeleton />
        <Skeleton width={"95%"} />
      </Box>
    </Box>
  );
};

export default JourneyCardSkeleton;
