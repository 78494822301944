import React, { useState, useRef } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Container,
  ProgressBar,
  Row,
  Col,
  Form,
  ListGroup,
  Card,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import parse from "html-react-parser";
import axios from "axios";

import { REACT_APP_API_URL } from "../../constants";
import {
  startFeedbackTest,
  removeAnswers,
  feedbackAnswers,
} from "../../services/actions/feedbackAction";
import { Capitalize, stringReplace } from "../widgets/Common";
import iconquestion from "../../img/icon-question.svg";
import Loader from "../widgets/Loader";
import Footer from "../widgets/Footer";
import { Sprig } from "../../index";

const FeedbackQuestion = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const target = useRef(null);
  const [data, setData] = useState("3");
  const [tool, setTool] = useState(true);
  // TO get the code from redux-store
  const codeData = useSelector((state) => state.initiatorDetail);
  let code = codeData.code;

  // To get the question data from redux-store
  const select = useSelector((state) => state.feedbackReducer);
  let current = select.currentQuestion;
  let question = select.questionData[current];
  let questionsCount = select.questionData.length;
  let progress = (current + 1) * 10;

  // To get the initiator detail from the redux-store.
  const selectorData = useSelector((state) => state.initiatorDetail);
  const username = selectorData.data.username;

  const [Load, setLoad] = useState({ loading: false });

  const nextStep = () => {
    const event = "sat_feedback_q" + (parseInt(current) + 1) + "_completed";
    console.debug("nextStep", event);

    axios.post(REACT_APP_API_URL + "events", { code, event });
    Sprig("identifyAndTrack", {
      eventName: event,
      properties: { code },
    });

    var answerData = question;
    answerData.answer = data;

    dispatch(feedbackAnswers(answerData));

    if (current === questionsCount - 1) {
      setLoad({ loading: true });
      // workaround to add last question before sending
      select.answerData.push(answerData);

      axios
        .post(REACT_APP_API_URL + "feedback-answers/" + code, {
          userId: selectorData.data.id,
          answers: select.answerData.map((a) => ({
            id: a.id,
            answer: a.answer,
          })),
        })
        .then((res) => {
          const resp = res.data;
          if (resp.status === "success") {
            localStorage.setItem("link", resp.link);
            const eventName = "sat_feedback_finished";
            axios.post(REACT_APP_API_URL + "events", {
              code,
              event: eventName,
            });
            Sprig("identifyAndTrack", {
              eventName,
              properties: { code },
            });

            setTimeout(() => {
              setLoad({ loading: false });
              navigate("/success/" + code);
            }, 2000);
          } else {
            navigate("/success/" + code);
          }
        })
        .catch((err) => {
          alert("CATCH");
          navigate("/feedback-start/" + code);
        });
    } else {
      dispatch(startFeedbackTest(select.questionData, current + 1));
      setData("3");
      navigate("/feedback-test/" + code);
    }
  };

  const goPrevious = () => {
    if (current === 0) {
      dispatch(removeAnswers(current));
      navigate("/feedback-start/" + code);
    } else {
      dispatch(removeAnswers(current - 1));
      dispatch(startFeedbackTest(select.questionData, current - 1));
      navigate("/feedback-test/" + code);
    }
  };

  const setToolTip = () => {
    setTool(false);
  };

  return (
    <div>
      {Load.loading ? (
        <Loader />
      ) : (
        <div>
          <ProgressBar
            className="topProgressBar"
            now={progress}
            max={questionsCount * 10}
          />
          <Container className="mt-4 mb-5">
            <Row className="justify-content-md-center">
              <Col xs={12} md={6}>
                <Container fluid>
                  <Row>
                    <Col>
                      <h6 className="text-secondary text-center fs-14 textopacity questionCount">
                        Question {current + 1}/{select.questionData.length}
                      </h6>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <h4 className="text-center">
                        {typeof question !== "undefined" ? (
                          <strong>
                            {stringReplace(
                              question.feedback_que,
                              Capitalize(username)
                            )}
                          </strong>
                        ) : (
                          <Navigate to={"/feedback-start/" + code} />
                        )}
                      </h4>
                    </Col>
                  </Row>

                  <Row className="mt-5 mb-1">
                    <Col>
                      <Form>
                        <ListGroup horizontal className="barSlider">
                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "0" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "100px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>

                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "1" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "80px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>

                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "2" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "60px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>

                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "3" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "40px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>

                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "4" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "60px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>

                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "5" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "80px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>

                          <ListGroup.Item
                            className={
                              "flex1 text-center border-0 p-0 align-self-end " +
                              (data === "6" ? "active" : "")
                            }
                          >
                            <span
                              className="horizentalBar"
                              style={{ height: "100px", margin: "auto" }}
                            ></span>
                          </ListGroup.Item>
                        </ListGroup>

                        <Form.Group controlId="formBasicRangeCustom">
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            target={target.current}
                            show={tool}
                            overlay={
                              <Tooltip id="tooltip-top"> Drag slider </Tooltip>
                            }
                          >
                            <Form.Control
                              type="range"
                              min="0"
                              max="6"
                              onClick={setToolTip}
                              onTouchStart={setToolTip}
                              step="1"
                              value={data}
                              onChange={(e) => setData(e.target.value)}
                              custom
                            />
                          </OverlayTrigger>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} md={6}>
                      <p className="text-black fs-14 letter-spacing-0 lh-20">
                        {typeof question !== "undefined" ? (
                          <strong>
                            {stringReplace(
                              question.feedback_resLeft,
                              Capitalize(username)
                            )}
                          </strong>
                        ) : (
                          <Navigate to={"/feedback-start/" + code} />
                        )}
                      </p>
                    </Col>
                    <Col xs={6} md={6}>
                      <p className="text-right text-black fs-14 letter-spacing-0 lh-20">
                        {typeof question !== "undefined" ? (
                          <strong>
                            {stringReplace(
                              question.feedback_resRight,
                              Capitalize(username)
                            )}
                          </strong>
                        ) : (
                          <Navigate to={"/feedback-start/" + code} />
                        )}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col xs={6} className="pr-2">
                      <Button
                        className="w-100"
                        variant="outline-primary"
                        onClick={goPrevious}
                      >
                        Go back
                      </Button>
                    </Col>
                    <Col xs={6} className="pl-2">
                      <Button
                        className="w-100"
                        variant="primary"
                        onClick={nextStep}
                      >
                        Continue
                      </Button>
                    </Col>
                  </Row>

                  {question?.knowledge_label !== undefined ? (
                    <Card
                      className="mt-5"
                      style={{
                        width: "100%",
                        border: "2px solid #E6E6E6",
                        borderRadius: "8px",
                      }}
                    >
                      <Card.Body
                        style={{ padding: "2.625rem 1.2rem 1.25rem 2.625rem" }}
                      >
                        <Card.Title className="fs-14 fSb mb-4">
                          {typeof question.knowledge_label !== "undefined" ? (
                            <strong>
                              <img
                                src={iconquestion}
                                alt="iconquestion"
                                style={{ margin: "-7px 1rem 0 0" }}
                              />
                              {question.knowledge_label}
                            </strong>
                          ) : (
                            ""
                          )}
                        </Card.Title>

                        {typeof question.knowledge_que_1 !== "undefined" ? (
                          <Card.Text className="text-secondary-666666 letter-spacing-0 fs-14 lh-22">
                            {parse(question.knowledge_que_1)}
                          </Card.Text>
                        ) : (
                          ""
                        )}

                        {typeof question.knowledge_que_2 !== "undefined" ? (
                          <Card.Text className="text-secondary-666666 letter-spacing-0 fs-14 lh-22">
                            {parse(question.knowledge_que_2)}
                          </Card.Text>
                        ) : (
                          ""
                        )}

                        {typeof question.knowledge_que_3 !== "undefined" ? (
                          <Card.Text className="text-secondary-666666 letter-spacing-0 fs-14 lh-22">
                            {parse(question.knowledge_que_3)}
                          </Card.Text>
                        ) : (
                          ""
                        )}

                        <p className="border-top border-2 mt-4"></p>
                        {typeof question.knowledge_que_src !== "undefined" ? (
                          <Card.Text className="text-secondary-666666 letter-spacing-0 fs-14 lh-22">
                            {parse(question.knowledge_que_src)}
                          </Card.Text>
                        ) : (
                          ""
                        )}
                      </Card.Body>
                    </Card>
                  ) : (
                    ""
                  )}
                  <Footer />
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default FeedbackQuestion;
