import { Box, Button } from "@mui/material";
import Text from "./Text";

const JourneyCard = ({ imgUrl, title, desc, onClick }) => {
  return (
    <Button
      className="btn-quiz"
      sx={{
        display: "flex",
        justifyContent: "start",
        // marginTop: "3rem",
        paddingX: "24px",
        paddingY: "16px",
        height: "104px",
        textAlign: "left",
        borderRadius: "20px",
        border: "1px solid rgba(0, 0, 0, 0.06)",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
        marginBottom: "15px",
        fontFamily: "euclid_circular_bmedium !important",
      }}
      onClick={onClick}
      fullWidth
    >
      <Box marginRight="24px">
        <Box
          sx={{
            backgroundImage: `url(${imgUrl})`,
            width: { xs: "60px", sm: "72px" },
            height: { xs: "60px", sm: "72px" },
            backgroundSize: "cover",
            backgroundColor: "white",
            borderRadius: "50%",
            backgroundPosition: "center",
          }}
        />
      </Box>
      <Box>
        <Text
          fontSize="18px"
          fontWeight={500}
          lineHeight="26px"
          color="#212121"
          marginBottom="6px"
        >
          {title}
        </Text>

        <Text
          fontSize="13px"
          fontWeight={500}
          lineHeight="18px"
          color="#A2A2A2"
        >
          {desc}
        </Text>
      </Box>
    </Button>
  );
};

export default JourneyCard;
