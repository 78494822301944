import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { startFeedbackTest } from "../../services/actions/feedbackAction";
import { get_initiator_detail } from "../../services/actions/initiatorDetail";
import { initiator_code } from "../../services/actions/initiatorDetail";
import { Capitalize } from "../widgets/Common";
import { AB_VERSION, REACT_APP_API_URL } from "../../constants";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import home from "../../img/mobile/home2.svg";
import ratings from "../../img/mobile/ratings.svg";
import store from "../../img/mobile/store.svg";
import appOfDay from "../../img/mobile/app_of_day.svg";
import iconfooter from "../../img/Footer.svg";
import { Sprig } from "../../index";
import mixpanel from "mixpanel-browser";
import { v4 } from "uuid";

const tempId = v4();

const FeedbackStart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const currentURL = "/feedback-start/" + id;

  localStorage.setItem("temporaryId", tempId);

  // To get user detail using useEffect
  useEffect(() => {
    console.log("aliasing as", tempId)
    mixpanel.alias(tempId);

    mixpanel.track(`$pageview-sat:feedback-started`, {
      page: `feedback-start`,
      abVersion: AB_VERSION,
    });

    const getUserName = () => {
      axios
        .get(REACT_APP_API_URL + "userdetails/" + id)
        .then((res) => {
          const resp = res.data;
          if (res.status === 200) {
            dispatch(get_initiator_detail(resp));
            dispatch(initiator_code(id));
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          navigate("/");
        });
    };

    // To get the initiator user details api.
    getUserName();

      }, [id, dispatch, navigate]);

  const selectorData = useSelector((state) => state.initiatorDetail);
  const username = selectorData.data.username;
  localStorage.setItem("username", username);

  let query = "";
  if (selectorData.data.version === "anger") {
    query = "?version=anger";
  } else if (selectorData.data.version === "onboarding") {
    query = "?version=onboarding";
  } else if (selectorData.data.version === "general") {
    query = "?version=general";
  }

  // To get the feedback questions for the initiator user api.
  const getData = () => {
    axios
      .get(REACT_APP_API_URL + "questions" + query)
      .then((res) => {
        const resp = res.data;
        if (res.status === 200) {
          const eventName = "sat_feedback_started";
          axios.post(REACT_APP_API_URL + "events", {
            code: id,
            event: eventName,
          });
          Sprig("identifyAndTrack", {
            eventName,
            properties: { code: id },
          });

          dispatch(startFeedbackTest(resp.data, 0));
          // navigate("/feedback-test-a");
          navigate("/feedback-test/" + id);
        } else {
          navigate(currentURL);
        }
      })
      .catch((err) => {
        navigate(currentURL);
      });
  };

  const clickDownload = () => {
    const eventName = "sat_feedback_success_download";
    axios.post(REACT_APP_API_URL + "events", {
      code: id,
      event: eventName,
    });
    Sprig("identifyAndTrack", {
      eventName,
      properties: { code: id },
    });

    window.location.href = "https://ahead.sng.link/Djdbk/x4ze/abs5";
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <Container fluid className="p-0 theme-body-width">
            <Row>
              <Col xs={12} className="text-center">
                <Container fluid className="pr-0 pl-0">
                  <Row className="justify-content-center">
                    <Col xs={12} md={10} className="pr-3 pl-3">
                      <h3 className="fSb fs-40">
                        {typeof username === "undefined" ? (
                          ""
                        ) : (
                          <React.Fragment>
                            Got 1 min to help{" "}
                            <span>{Capitalize(username)} </span>?
                          </React.Fragment>
                        )}
                      </h3>

                      <h6 className="text-a2a2a2 lh-30 mt-3 fs-18">
                        {typeof username === "undefined" ? (
                          ""
                        ) : (
                          <React.Fragment>
                            10 anonymous questions and a small gift for you at
                            the end!
                          </React.Fragment>
                        )}
                      </h6>

                      <Container fluid>
                        <Row className="justify-content-center">
                          <Col md={12}>
                            <Container fluid>
                              <Row className="justify-content-md-center text-center text-md-left">
                                <img src={home} alt="ahead" width="100%" />
                              </Row>
                              <Row>
                                <Col xs={12}></Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                      <Button
                        className="btn-primary btn-lg w-100 d-none d-md-block"
                        style={{
                          margin: "3rem auto 0",
                          height: "58px",
                        }}
                        onClick={getData}
                      >
                        <span className="text-white d-block">
                          {typeof username === "undefined" ? (
                            ""
                          ) : (
                            <React.Fragment>Let's go!</React.Fragment>
                          )}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col
                      xs={6}
                      className="bg-white mb-4 mt-4 justify-content-center align-self-center"
                    >
                      <img src={appOfDay} alt="footer" />
                    </Col>
                    <Col
                      xs={6}
                      className="bg-white mb-4 mt-4 justify-content-center align-self-center"
                    >
                      <img
                        src={store}
                        alt="footer"
                        style={{ cursor: "pointer" }}
                        onClick={() => clickDownload()}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className="bg-white mb-0 mt-0 justify-content-center align-self-center"
                    >
                      <img src={ratings} alt="iconfooter" />
                    </Col>
                    <Col xs={12}>
                      <p className="fs-14 bg-white mb-0 footer mt-4 text-center text-secondary letter-spacing-0 textopacity">
                        <span>
                          <img
                            src={iconfooter}
                            alt="iconfooter"
                            style={{ margin: "-7px 0 0 0" }}
                          />
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Button
                        className="btn-primary btn-lg d-block d-md-none"
                        style={{
                          margin: "3rem auto 0",
                          height: "58px",
                          position: "fixed",
                          bottom: "16px",
                          left: "24px",
                          right: "24px",
                        }}
                        onClick={getData}
                      >
                        <span className="text-white d-block">
                          {typeof username === "undefined" ? (
                            ""
                          ) : (
                            <React.Fragment>Let's go!</React.Fragment>
                          )}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default FeedbackStart;
