import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { REACT_APP_STRAPI_KEY, REACT_APP_STRAPI_URL } from "../../constants";
import axios from "axios";
import { Box, Skeleton, Button } from "@mui/material";
import NavbarLogo from "../ui/NavbarLogo";
import Text from "../ui/Text";
import thunderImg from "../../img/thunder.svg";
import UpcomingJourney from "../ui/UpcomingJourney";
import mixpanel from "mixpanel-browser";
import { AB_VERSION } from "../../constants";

const EmotionIntro = () => {
  const { emotionCategory } = useParams();
  const navigate = useNavigate();
  const [header, setHeader] = useState("");
  const [subHeader, setSubheader] = useState("");
  const [heroImg, setHeroImg] = useState("");
  const [traits, setTraits] = useState("");

  const startQuiz = () => {
    navigate(`/${emotionCategory}/device`);
  };

  useEffect(() => {
    mixpanel.track(`$pageview-sat:${emotionCategory}-intro`, {
      page: `${emotionCategory}/intro`,
      abVersion: AB_VERSION,
    });

    const getEmotionDetail = () => {
      let url = REACT_APP_STRAPI_URL + `/emotion-categories/${emotionCategory}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${REACT_APP_STRAPI_KEY}`,
          },
        })
        .then((res) => {
          const response = res.data.data;

          console.log(response);

          setHeader(response.header);
          setSubheader(response.subheader);
          setHeroImg(response.logo.url);

          const t = response.traits.map((e) => e.name) ?? [];
          setTraits(t);
        })
        .catch((error) => {
          console.warn("unable to fetch emotion list", error);
        });
    };

    getEmotionDetail();
  }, [emotionCategory]);

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        <NavbarLogo />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: {
                lg: "80%",
                sm: "calc(100% - 80px)",
                xs: "calc(100% - 40px)",
              },
              marginTop: { xs: "0px", sm: "95px" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% - 280px)" },
                paddingRight: { xs: 0, sm: 2 },
                marginTop: { xs: "50px", sm: 0 },
              }}
            >
              <Text
                color="#6442EF"
                fontWeight={500}
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "24px",
                  },
                  marginBottom: {
                    xs: "0px",
                    sm: "16px",
                  },
                }}
                lineHeight="33px"
              >
                Your Journey
              </Text>

              {!header && (
                <>
                  <Box marginBottom={"30px"}>
                    <Skeleton width={"50%"} height={"60px"} />
                  </Box>
                  <Skeleton />
                  <Skeleton width={"90%"} />
                  <Skeleton width={"60%"} />
                  <Skeleton width={"50%"} />
                  <Skeleton width={"70%"} />
                  <Box display="flex" flexWrap="wrap" marginTop={"30px"}>
                    <Skeleton
                      width={"200px"}
                      height={"50px"}
                      sx={{ marginRight: "10px" }}
                    />
                    <Skeleton
                      width={"250px"}
                      height={"50px"}
                      sx={{ marginRight: "10px" }}
                    />
                    <Skeleton
                      width={"180px"}
                      height={"50px"}
                      sx={{ marginRight: "10px" }}
                    />
                  </Box>
                </>
              )}

              {header && (
                <>
                  <Text
                    color="#212121"
                    fontWeight={500}
                    lineHeight="59.84px"
                    sx={{
                      fontSize: {
                        xs: "28px",
                        sm: "40px",
                      },
                      marginBottom: {
                        xs: "8px",
                        sm: "24px",
                      },
                    }}
                  >
                    {header}
                  </Text>

                  <Text
                    color="rgba(33, 33, 33, 0.6)"
                    fontWeight={400}
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "24px",
                      },
                      marginBottom: {
                        xs: "24px",
                        sm: "40px",
                      },
                    }}
                    lineHeight="42px"
                  >
                    {subHeader}
                  </Text>

                  <Box
                    sx={{
                      width: { xs: "100%", md: "280px" },
                      marginBottom: "40px",
                      display: { xs: "flex", sm: "none" },
                      justifyContent: "center",
                    }}
                  >
                    {!heroImg && (
                      <Skeleton
                        width="100%"
                        sx={{
                          height: "400px",
                          transform: "scale(1, 1)",
                          borderRadius: "20px",
                        }}
                      />
                    )}
                    <img
                      src={heroImg}
                      width="50%"
                      style={{ borderRadius: "20px" }}
                      alt="hero-img"
                    />
                  </Box>

                  <Text
                    color="#212121"
                    fontWeight={500}
                    sx={{
                      fontSize: { xs: "16px", sm: "20px", display: "flex" },
                    }}
                    lineHeight="33px"
                    marginBottom="18px"
                  >
                    <span>
                      <img
                        src={thunderImg}
                        style={{ paddingRight: "12px" }}
                        alt="thunder"
                      />
                    </span>
                    Perfect for those struggling with...
                  </Text>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    sx={{
                      pb: "16px",
                    }}
                  >
                    {traits.map((e, i) => (
                      <Box
                        key={i}
                        sx={{
                          fontSize: { xs: "16px", sm: "18px" },
                          fontWeight: 500,
                          paddingX: "24px",
                          paddingY: { xs: "12px", sm: "14px" },
                          border: "1.5px solid rgba(0, 0, 0, 0.08)",
                          borderRadius: "75px",
                          marginRight: "6px",
                          marginTop: "9px",
                        }}
                      >
                        {e}
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "280px" },
                display: { xs: "none", sm: "inline" },
              }}
            >
              {!heroImg && (
                <Skeleton
                  width="100%"
                  sx={{
                    height: "400px",
                    transform: "scale(1, 1)",
                    borderRadius: "20px",
                  }}
                />
              )}
              <img
                src={heroImg}
                width="100%"
                style={{ borderRadius: "20px" }}
                alt="hero-img"
              />
            </Box>
          </Box>

          <Button
            className="btn-cta"
            variant="contained"
            color="primary"
            sx={{
              fontWeight: 500,
              height: "64px",
              fontSize: { xs: "14px", sm: "18px" },
              width: {
                md: "400px",
                sm: "calc(100% - 80px)",
                xs: "calc(100% - 40px)",
              },
              display: {
                xs: "none",
                md: "initial",
              },
              marginTop: "50px",
              marginBottom: "80px",
            }}
            onClick={startQuiz}
          >
            <Text fontSize="18px" fontWeight={500} color="#ffffff">
              Let's go
            </Text>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          paddingBottom: "10px",
          display: {
            xs: "flex",
            md: "none",
          },
          justifyContent: "center",
        }}
      >
        <Button
          className="btn-cta"
          variant="contained"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "14px", sm: "18px" },
            marginTop: "28px",
            width: {
              md: "400px",
              sm: "calc(100% - 80px)",
              xs: "calc(100% - 40px)",
            },
          }}
          onClick={startQuiz}
        >
          <Text fontSize="18px" fontWeight={500} color="#ffffff">
            Let's go
          </Text>
        </Button>
      </Box>

      <UpcomingJourney />
    </>
  );
};

export default EmotionIntro;
