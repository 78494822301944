import { Box, Grid, Button } from "@mui/material";
import Text from "./Text";
import ghost1 from "../../img/gray-journey-ghost-1.png";
import ghost2 from "../../img/gray-journey-ghost-2.png";
import ghost3 from "../../img/gray-journey-ghost-3.png";
import ghost4 from "../../img/gray-journey-ghost-4.png";

const UpcomingJourney = () => {
  return (
    <Box sx={{ bgcolor: "#f5f5f5", paddingY: "80px" }}>
      <Text
        textAlign="center"
        fontWeight={500}
        fontSize="24px"
        lineHeight={"38px"}
        marginBottom="40px"
        marginX="40px"
      >
        Join our other upcoming journeys
      </Text>

      <Grid
        container
        spacing={2}
        sx={{ width: { xs: "90%", lg: "1164px" }, marginX: "auto" }}
      >
        <Grid item xs={12} sm={6} md={3} sx={{ marginRight: { xs: 2, sm: 0 } }}>
          <Box
            sx={{
              bgcolor: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.06)",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                height: "192px",
                backgroundImage: `url(${ghost1})`,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Box paddingX="24px" paddingBottom="24px">
              <Text
                textAlign="center"
                fontWeight={500}
                fontSize="18px"
                lineHeight={"32px"}
                marginY="16px"
              >
                Win people over
              </Text>

              <Button
                className="btn-cta"
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 500,
                  height: "54px",
                  fontSize: { xs: "14px", sm: "18px" },
                  paddingY: { xs: "12px", sm: "20px" },
                  marginBottom: "5px",
                }}
                fullWidth
              >
                <Text fontSize="18px" fontWeight={500} color="#ffffff">
                  Join waitlist
                </Text>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ marginRight: { xs: 2, sm: 0 } }}>
          <Box
            sx={{
              bgcolor: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.06)",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                height: "192px",
                backgroundImage: `url(${ghost2})`,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Box paddingX="24px" paddingBottom="24px">
              <Text
                textAlign="center"
                fontWeight={500}
                fontSize="18px"
                lineHeight={"32px"}
                marginY="16px"
              >
                Find peace of mind
              </Text>

              <Button
                className="btn-cta"
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 500,
                  height: "54px",
                  fontSize: { xs: "14px", sm: "18px" },
                  paddingY: { xs: "12px", sm: "20px" },
                  marginBottom: "5px",
                }}
                fullWidth
              >
                <Text fontSize="18px" fontWeight={500} color="#ffffff">
                  Join waitlist
                </Text>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ marginRight: { xs: 2, sm: 0 } }}>
          <Box
            sx={{
              bgcolor: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.06)",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                height: "192px",
                backgroundImage: `url(${ghost3})`,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Box paddingX="24px" paddingBottom="24px">
              <Text
                textAlign="center"
                fontWeight={500}
                fontSize="18px"
                lineHeight={"32px"}
                marginY="16px"
              >
                See the positive
              </Text>

              <Button
                className="btn-cta"
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 500,
                  height: "54px",
                  fontSize: { xs: "14px", sm: "18px" },
                  paddingY: { xs: "12px", sm: "20px" },
                  marginBottom: "5px",
                }}
                fullWidth
              >
                <Text fontSize="18px" fontWeight={500} color="#ffffff">
                  Join waitlist
                </Text>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ marginRight: { xs: 2, sm: 0 } }}>
          <Box
            sx={{
              bgcolor: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.06)",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                height: "192px",
                backgroundImage: `url(${ghost4})`,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Box paddingX="24px" paddingBottom="24px">
              <Text
                textAlign="center"
                fontWeight={500}
                fontSize="18px"
                lineHeight={"32px"}
                marginY="16px"
              >
                Live with intention
              </Text>

              <Button
                className="btn-cta"
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 500,
                  height: "54px",
                  fontSize: { xs: "14px", sm: "18px" },
                  paddingY: { xs: "12px", sm: "20px" },
                  marginBottom: "5px",
                }}
                fullWidth
              >
                <Text fontSize="18px" fontWeight={500} color="#ffffff">
                  Join waitlist
                </Text>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpcomingJourney;
