import { Box } from "@mui/material";
import backIcon from "../../img/back-icon.svg";
import logo from "../../img/Logo.svg";

const InsertsNav = ({ goToPrevQuestion }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <img
          src={backIcon}
          alt="back-icon"
          height={"22px"}
          onClick={goToPrevQuestion}
          style={{
            cursor: "pointer",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexGrow: "1",
            color: "#A2A2A2",
            fontWeight: 500,
            paddingRight: "40px",
          }}
        >
          <img src={logo} alt="ahead-logo" style={{ height: "24px" }} />
        </Box>
      </Box>
    </>
  );
};

export default InsertsNav;
